<template>
  <div class="facial-features q-w360 q-fit">
    <question-with-error-wrap :error="getFieldsError(['firstBloodVesselOccurrance'])">
      <single-answer-question
        title="label.initialCapillaryOccurrence"
        :value="firstBloodVesselOccurrance"
        :options="$options.rednessDurationOptions"
        dysfunction="skin-redness"
        @input="onFieldChange('firstBloodVesselOccurrance', $event)"
      />
    </question-with-error-wrap>

    <question-with-error-wrap
      class="q-mt24 q-mt32-md"
      :error="getFieldsError(['bloodVesselLocations'])"
    >
      <face-zone-multi-select
        id="facial-capillary-locations"
        title="label.capillaryLocations"
        :value="bloodVesselLocations"
        :options="$options.capillaryFaceLocations"
        :gender="gender"
        hint="label.selectAllRegionsThatApply"
        field-name="blood-vessels"
        @input="onFieldChange('bloodVesselLocations', $event)"
      />
    </question-with-error-wrap>
  </div>
</template>

<script>
import QuestionWithErrorWrap from '@/modules/questionnaire/new-design-components/questions/QuestionWithErrorWrap';
import SingleAnswerQuestion from '@/modules/questionnaire/new-design-components/questions/SingleAnswerQuestion';
import FaceZoneMultiSelect from '@/modules/questionnaire/new-design-components/questions/face-zone-input/FaceZoneMultiSelect';

import { stepTemplateMixin } from '@/modules/questionnaire/mixins/stepTemplateMixin';

import { REDNESS_DURATION } from '@/modules/questionnaire/api/constants';
import { CAPILLARY_FACE_LOCATION_OPTIONS } from '@/modules/questionnaire/constants/steps/skinRedness.js';

const REDNESS_DURATION_OPTIONS = [
  {
    value: REDNESS_DURATION.UNDER_A_YEAR,
    text: 'capillaryOccurrence.underAYear'
  },
  {
    value: REDNESS_DURATION.ONE_TO_THREE_YEARS,
    text: 'capillaryOccurrence.oneToThreeYears'
  },
  {
    value: REDNESS_DURATION.OVER_THREE_YEARS,
    text: 'capillaryOccurrence.overThreeYears'
  }
];

export default {
  name: 'CapillaryLocationsTemplate',
  components: { QuestionWithErrorWrap, SingleAnswerQuestion, FaceZoneMultiSelect },
  rednessDurationOptions: REDNESS_DURATION_OPTIONS,
  capillaryFaceLocations: CAPILLARY_FACE_LOCATION_OPTIONS,
  mixins: [stepTemplateMixin],
  props: {
    firstBloodVesselOccurrance: {
      type: String,
      default: ''
    },
    bloodVesselLocations: {
      type: Array,
      default: () => []
    },
    gender: {
      type: String,
      required: true
    }
  }
};
</script>
